
import { ref } from "vue"
import NetworkCollection from "../networks/NetworkCollection.vue"
import TaskCollection from "../tasks/TaskList.vue"

export default {
  name: "OrganizationDashboard",
  components: { NetworkCollection, TaskCollectionNew: TaskCollection },
  props: {
    organization: {
      type: Object,
      default: null,
    },
  },
  emits: ["value-changed"],
  setup(props: any, { emit }: any) {
    const organizationComments = ref(
      props.organization && props.organization.comments ? props.organization.comments : ""
    )

    /*watch(
      () => props.organization,
      (newValue, oldValue) => {
        if (newValue) {
          comments.value = newValue.comments
        }
      }
    )*/

    function reloadParent() {
      emit("value-changed")
    }

    return {
      organizationComments,
      reloadParent,
    }
  },
}
